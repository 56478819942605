<template>
<div>
  <div id="movie"><video src="" muted playsinline autoplay></video></div>
    <mv-pc v-if="!$isMobile()" ref="mvpc" @clickMoviePin="showConceptMovie"></mv-pc>
    <mv-sp v-else ref="mvpc" @clickMoviePin="showConceptMovie"></mv-sp>
    <header>
      <nav class="global">
        <a href="/"><img src="assets/images/logo.svg" alt="Raku-P [ラクピー]" class="logo"></a>
        <ul>
          <li class="active"><a href="/">HOME</a></li>
          <li><a href="/raku-p/">Raku-P</a></li>
          <li><a href="/owner/">オーナー</a></li>
          <li><a href="/merit/">会員登録のメリット</a></li>
          <li><a href="/qa/" class="disabled">よくある質問</a></li>
          <li class="right"><a href="/service/" target="_blank"><img src="/assets/images/ico_raku-p.svg" alt="">Raku-P<br>サービスサイト</a></li>
        </ul>
      </nav>
      <nav id="submenu" class="">
          <div class="cover"></div>
          <ul>
            <li><a href="" id="play-movie">Concept Movie</a></li>
            <li class="for-sp"><a href="/raku-p/">Raku-P</a></li>
            <li class="for-sp"><a href="/owner/">オーナー</a></li>
            <li><a href="/merit/">会員登録のメリット</a></li>
            <li class="for-sp"><a href="/qa/" class="disabled">よくある質問</a></li>
            <li><a href="/contact/general/">お問い合わせ</a></li>
            <li><a href="/news/" class="disabled">News</a></li>
            <li><a href="/service/MemberRegist" target="_blank">会員登録</a></li>
            <li class="for-sp"><a href="/service/"><img src="/assets/images/logo_white.svg" alt="">Raku-P サービスサイト</a></li>
            <li><a href="https://instagram.com/rakup_official?igshid=YmMyMTA2M2Y=" target="_blank"><img src="/assets/images/ico_instagram-w.svg" alt="公式インスタグラム"></a></li>
          </ul>
      </nav>
      <a href="#" id="menubtn"></a>
    </header>
      <router-view v-slot="{ Component, route }">
        <transition name="slide">
          <component :is="Component" :key="route.path" />
        </transition>
      </router-view>
    <section id="intro">
      <div class="content">
        <div><img src="assets/images/top/main_copy.svg" alt="" class="copy"></div>
        <p>「未来の駐車場の役割ってなんだろう。」<br>ただ駐車するだけでなく、街と街をつなげる役割、人と人とが会える場所。これまでにない新たな駐車場の可能性にRaku-Pはチャレンジします。<br>ロック板の廃止や自動精算の導入など、利用者や街のことを考えた新たなカタチ。時には駐車場の”駐車”ではなく、人が集える”場”に変身したり。<br>そんな駐車場だけにとどまらず、移動に新たな価値を提供することをRaku-Pは目指しています。</p>
      </div>
    </section>
    <section id="news">
      <div class="content">
        <h2>News</h2>
        <div>
          <ul>
            <li><a href="#" class="disabled-action"><span class="date">2022. 11. 01</span>Raku-P公式ページを新たに開設しました。</a></li>
          </ul>
          <a class="arrow disabled" href="/news/"></a>
        </div>
      </div>
    </section>
</div>
</template>

<script setup>
import { reactive, onMounted, ref } from 'vue'
import { useCookies } from 'vue3-cookies'
import { gsap } from 'gsap'
import { useMobileDetection } from 'vue3-mobile-detection'
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useRouter } from 'vue-router'

import MvPc from '../components/MvPc.vue'
import MvSp from '@/components/MvSp.vue'

const router = useRouter()
// gsap.registerPlugin(ScrollTrigger)
const { isMobile } = useMobileDetection()
const { cookies } = useCookies()
const mvpc = ref(null)
const debug = false
const mydata = reactive({
  childPath: router.currentRoute.value.name,
  isFirst: true
})

onMounted(() => {
  const cookieValue = cookies.get('ft')
  if (cookieValue !== '1') {
    console.log('first time')
    mydata.isFirst = true
    cookies.set('ft', '1', 0)
  } else {
    mydata.isFirst = false
    console.log('second time')
  }

  if (mydata.childPath === 'home' && !debug && mydata.isFirst) {
    document.body.style.overflow = 'hidden'
    const video = document.getElementsByTagName('video')[0]
    video.src = `assets/videos/raku-op_0${getRandomInt(1, 5)}.mp4`
    // video.play()
    // video.load()
    video.addEventListener('canplay', (event) => {
      document.getElementById('movie').style.display = 'block'
      console.log('video ready')
      // video.play()
    })
    video.addEventListener('timeupdate', videoTimeUpdate, false)
  } else {
    document.getElementById('movie').remove()
    mvpc.value.mapShow()
  }
})

// ビデオフレーム監視
const videoTimeUpdate = (e) => {
  const video = e.currentTarget
  if (video.currentTime >= 4) {
    video.removeEventListener('timeupdate', videoTimeUpdate, false)
    gsap.to(video, {
      alpha: 0,
      duration: 0.8,
      onComplete: () => {
        if (!isMobile())document.body.style.overflow = 'auto'
        document.getElementById('movie').remove()
        // initMap()
        mvpc.value.mapShow()
      }
    })
  }
}

// eslint-disable-next-line no-unused-vars
const showConceptMovie = () => {
  window.playFullSizeMovie(null, true, '/assets/videos/concept_movie_s.mp4')
}

const getRandomInt = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min)
}

</script>

<style lang="scss">

  #movie{
    display: none;
    text-align: center;
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    // display: none;
    video{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  section{
    .content{
      max-width: 90%;
      margin: 100px 12.4vw;
      max-width: 1440px;
      @include mq(sp) {
        flex-direction: column;
        margin: 60px 8vw;
      }
    }
  }

  .copy{
      position: absolute;
      width: 30vw;
      max-width: 511px;
      min-width: 300px;
      left: 6.2vw;
      @include mq(sp) {
        left: 0;
        position: relative;
      }
    }

  section#intro{
    background-color: #F4F4F4;
    font-size: 18px;
    line-height: 2.5em;
    display: flex;
    @include mq(sp) {
      font-size: 16px;
    }

    .content{
      display: flex;
      // align-items: center;
      gap: 5.1vw;
      > div{
        flex: 1;
        // margin-left: 4.68%;
      }
      > p{
        margin: 0;
        text-align: left;
        width: 66%;
        // margin-left: 4.68%;
      }
      @include mq(sp) {
        > p {
          width: 100%;
        }
      }
    }
}

section#news{

  .content{
    display: flex;
    justify-content: flex-end;

    > div {
      flex: 1;
    }
    > h2{
      margin: 0;
      width: 18.4vw;
      font-size: 18px;
      &:before{
        content: "ー";
        display: inline-block;
        margin-right: .5em;
        transform: scaleX(80%);
      }
    }

    @include mq(sp) {
      gap: 50px;
      > div{
        text-align: right;
        .arrow{

        }
      }
    }
  }

  ul{
    margin: 0 0 40px 0;
    padding: 0;
    list-style: none;
    li{
      text-align: left;
      margin-bottom: 1em;
      @include mq(sp) {
        margin-bottom: 1.6em;
      }
      &:last-child{
        margin-bottom: 0;
      }
      a{
        text-decoration:  none;
        display: flex;
        font-size: 15px;
        line-height: 1.8em;
        @include mq(sp) {
          flex-direction: column;
        }
        .date{
          color: $rakup-red;
          white-space: nowrap;
          display: inline-block;
          margin-right: 2em;
        }
      }
    }
  }
}

@keyframes slide {
  0% {
    transform: translateX(10px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-out {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(10px);
    opacity: 0;
  }
}

  /* transition に与えたnameに基づいて v -> fade */
  .slide-enter-active {
    opacity: 0;
  }
  .slide-enter-to {
    animation: slide .5s;
  }
  .slide-leave-to {
    animation: slide-out .3s;
  }

</style>
