<template>
  <div id="mv">
    <!-- <img src="assets/images/top/main_copy.svg" alt="" class="copy"> -->
    <svg id="move" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="406" height="2934" viewBox="0 0 406 2934">
      <path id="move13" d="M.72,887.949l-.441-.9,102.278-50.225.055-.013a80.353,80.353,0,0,1,9.561-1.507,51.559,51.559,0,0,1,8.3-.188,20.085,20.085,0,0,1,7.475,1.734c7.939,3.952,52.123,30.571,52.569,30.84l-.516.856c-.445-.268-44.595-26.867-52.5-30.8-3.191-1.588-8.454-2.089-15.222-1.447a79.844,79.844,0,0,0-9.385,1.474Z" fill="red"/>
      <path id="move11" d="M.225,78.447l-.45-.893,155-78,.45.893Z" transform="translate(233.5 2595.5)" fill="red"/>
      <path id="move10" d="M.223,111.448l-.446-.9,223-111,.446.9Z" transform="translate(141.5 2425.5)" fill="red"/>
      <path id="move9" d="M.215,49.452l-.43-.9,103-49,.43.9Z" transform="translate(0.5 1934.5)" fill="red"/>
      <path id="move8" d="M.218,41.45l-.437-.9,84.5-41,.437.9Z" transform="translate(0 1925.5)" fill="red"/>
      <path id="move7" d="M405.243,2444.493,37.4,2268.182l.432-.9,367.844,176.311Z" fill="red"/>
      <path id="move6" d="M.215,79.451l-.43-.9,166-79,.43.9Z" transform="translate(0.5 2339.5)" fill="red"/>
      <path id="move5" d="M.217,68.45l-.434-.9,141-68,.434.9Z" transform="translate(0.5 2326.5)" fill="red"/>
      <path id="move4" d="M405.785,193.452l-406-193,.429-.9,406,193Z" transform="translate(0.5 1334.5)" fill="red"/>
      <path id="move3" d="M405.785,193.452l-406-193,.429-.9,406,193Z" transform="translate(0.5 1311.5)" fill="red"/>
      <path id="move2" d="M.217,196.45l-.435-.9,406-196,.435.9Z" transform="translate(0.5 709.5)" fill="red"/>
      <path id="move1" d="M.217,196.45l-.435-.9,406-196,.435.9Z" transform="translate(0.5 691.5)" fill="red"/>
      <path id="move12" d="M131.859,1372.776a23.167,23.167,0,0,1-5.581-.759l-.053-.013-.049-.024c-5.071-2.521-16.4-7.926-25.507-12.269-3.983-1.9-7.744-3.694-10.466-5l.433-.9c2.721,1.306,6.482,3.1,10.464,5,9.078,4.33,20.37,9.716,25.472,12.251,6.923,1.707,10.039.056,13.053-1.541.444-.235.9-.478,1.361-.7,10.963-5.666,65.721-33,89.106-44.68,4.886-2.439,8.417-4.2,10.028-5.008l.447.894c-1.612.806-5.142,2.569-10.029,5.009-23.387,11.677-78.156,39.02-89.1,44.676l-.01.005c-.445.218-.878.447-1.336.69A16.039,16.039,0,0,1,131.859,1372.776Z" fill="red"/>
      <path id="move14" d="M215.785,103.451l-216-103,.43-.9,216,103Z" transform="translate(23.5 1887.5)" fill="red"/>
      <path id="move15" d="M.216,168.451l-.432-.9,351-168,.432.9Z" transform="translate(41.5 138.5)" fill="red"/>
      <path id="move16" d="M130.781,64.449l-131-64,.439-.9,131,64Z" transform="translate(251.5 620.5)" fill="red"/>
    </svg>
  </div>
</template>

<script setup>
import { reactive, onBeforeUnmount, defineExpose, onMounted, defineEmits } from 'vue'
import { useRouter } from 'vue-router'
import { useMobileDetection } from 'vue3-mobile-detection'

// PixiJSを読み込み
import * as PIXI from 'pixi.js'
import { gsap } from 'gsap'
import { PixiPlugin } from 'gsap/PixiPlugin'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const emit = defineEmits(['clickMoviePin'])
const { isMobile } = useMobileDetection()
const router = useRouter()
gsap.registerPlugin(PixiPlugin)
gsap.registerPlugin(MotionPathPlugin)
gsap.registerPlugin(ScrollTrigger)

const mydata = reactive({
  stageSize: {
    width: 406,
    height: 2934
  },
  container: {},
  moveObjects: [],
  state: 'movie',
  childPath: router.currentRoute.value.name
})

const drawTimer = 5000
let drawGraphics = null
// let viewport = null
let maskContainer = null
let app = null

onBeforeUnmount(() => {
  app?.destroy()
})

onMounted(() => {
  init()
})

// イラストマップの初期化
// eslint-disable-next-line no-unused-vars
const init = () => {
  mydata.state = 'intro'

  gsap.fromTo('#intro .copy',
    { y: -120, alpha: 0 }, {
      y: 0,
      alpha: 1,
      ease: 'power1.out',
      scrollTrigger: {
        trigger: '#intro p',
        end: '+=200',
        // markers: true,
        scrub: true
      }
    }
  )

  // ステージを作成
  app = new PIXI.Application({
    backgroundColor: 0xffffff,
    // backgroundAlpha: 0,
    width: mydata.stageSize.width,
    height: mydata.stageSize.height,
    antialias: false, // アンチエイリアスを有効にする
    resolution: window.devicePixelRatio || 1 // 解像度に合わせた拡大率を指定する
    // autoDensity: true // CSSで見た目のサイズの戻してくれる,
    // resizeTo: document.getElementById('mv') // windowのリサイズに合わせてstage(canvas)をリサイズする
  })
  app.renderer.plugins.interaction.autoPreventDefault = true
  app.renderer.view.style.touchAction = 'none'
  // app.renderer.plugins.interaction.moveWhenInside = true

  PixiPlugin.registerPIXI(PIXI)
  document.getElementById('mv').appendChild(app.view)

  const mapContainer = new PIXI.Container()
  const mapContainerC = new PIXI.Container()

  /* -------------------------------------------------------------------------- */
  // 地面・駐車場
  const groundTexture = PIXI.Texture.from('assets/images/top/ground_sp.png')
  const groundSprite = new PIXI.Sprite(groundTexture)
  mydata.container.ground = groundSprite
  // mapContainer.addChild(baseSprite)
  mapContainer.addChild(groundSprite)

  // 建物
  const buildTexture = PIXI.Texture.from('assets/images/top/build_sp.png')
  const buildsSprite = new PIXI.Sprite(buildTexture)
  mydata.container.builds = buildsSprite
  mapContainer.addChild(buildsSprite)

  // 人・その他植物
  const otherTexture = PIXI.Texture.from('assets/images/top/other_sp.png')
  const othersSprite = new PIXI.Sprite(otherTexture)
  mydata.container.others = othersSprite
  mapContainer.addChild(othersSprite)

  /* -------------------------------------------------------------------------- */
  const groundColorTexture = PIXI.Texture.from('assets/images/top/color_sp.png')
  const groundColorSprite = new PIXI.Sprite(groundColorTexture)
  groundColorSprite.blendMode = 2
  mydata.container.groundColor = groundColorSprite
  mapContainer.addChild(groundColorSprite)
  // mapContainerC.addChild(groundColorSprite)
  /* -------------------------------------------------------------------------- */

  const movementSprite = new PIXI.Sprite()
  mydata.container.movementSprite = movementSprite
  mapContainer.addChild(movementSprite)

  const mainContainer = new PIXI.Container()
  mydata.container.mainContainer = mainContainer
  mainContainer.addChild(mapContainer)
  mainContainer.addChild(mapContainerC)

  const lineContainer = new PIXI.Container()

  const brt = new PIXI.BaseRenderTexture(mydata.stageSize.width, mydata.stageSize.height, PIXI.SCALE_MODES.NEAREST)
  const rt = new PIXI.RenderTexture(brt)
  const sprite = new PIXI.Sprite(rt)
  lineContainer.addChild(sprite)
  mydata.container.lineContainer = lineContainer

  // デバッグ用
  // lineContainer.scale.x = 0.7
  // lineContainer.scale.y = 0.7

  // viewport.addChild(lineContainer)
  // viewport.addChild(mainContainer)
  // viewport.visible = false
  app.stage.addChild(lineContainer)
  app.stage.addChild(mainContainer)
  app.stage.visible = false
  // viewport.fitHeight(viewport.worldHeight, true)
  // viewport.moveCenter(203 / 2, 1467 / 2)

  /* -------------------------------------------------------------------------- */
  /*                                   マスクの準備                                   */
  /* -------------------------------------------------------------------------- */
  const drawBRT = new PIXI.BaseRenderTexture(mydata.stageSize.width, mydata.stageSize.height, PIXI.SCALE_MODES.NEAREST)
  const drawRT = new PIXI.RenderTexture(drawBRT)
  const drawSprite = new PIXI.Sprite(drawRT)
  // viewport.addChild(drawSprite)
  drawSprite.name = 'ds'
  app.stage.addChild(drawSprite)
  app.stage.interactive = true
  mainContainer.mask = drawSprite

  maskContainer = new PIXI.Container()
  drawGraphics = new PIXI.Graphics()
  drawGraphics.name = 'dg'
  // drawGraphics.cacheAsBitmap = true
  drawGraphics.filters = [new PIXI.filters.BlurFilter(5)]
  maskContainer.addChild(drawGraphics)

  /* -------------------------------------------------------------------------- */

  app.ticker.add((delta) => {
    // console.log(mydata.container.baloon)
    for (const elem of mydata.moveObjects) {
      elem.children[1].alpha = 0
    }

    if (mydata.state === 'draw' || mydata.state === 'intro') {
      mapContainer.children[3].alpha = 0
      app.renderer.render(mapContainer, { renderTexture: rt })
      mapContainer.children[3].alpha = 1
    }

    for (const elem of mydata.moveObjects) {
      elem.children[1].alpha = 1
    }

    // 手書きのテクスチャ更新
    if (mydata.state === 'draw') {
      app.renderer.render(maskContainer, { renderTexture: drawRT, clear: true })
    }
  })

  // アニメーションオブジェクトの追加
  addMovement('baloon', 200, 950)
  addMovement('cloudD', 0.1, 0.1)

  // window.addEventListener('resize', debounce(resizeHandler, 1000))
  // mapShow()
}

const showMain = () => {
  mydata.state = 'main'
  const pinContainer = new PIXI.Container()
  pinContainer.interactiveChildren = true
  mydata.container.pinContainer = pinContainer
  const pinRPTexture = PIXI.Texture.from('assets/images/top/pin_raku-p.png')
  const pinRPLTexture = PIXI.Texture.from('assets/images/top/pin_raku-p-light.png')
  const pinRPBexture = PIXI.Texture.from('assets/images/top/pin_raku-p-both.png')

  // viewport.addChild(pinContainer)
  app.stage.addChild(pinContainer)

  // ピンの追加 (id, テクスチャ, 座標x、座標y、吹き出しテキスト、吹き出し色)
  addPin(1, pinRPLTexture, 176, 111, '駐車場にできない部分も\n駐車場にできる', '0x00A2E9')
  addPin(2, pinRPTexture, 166, 492, '会員向けWポイント')
  addPin(3, pinRPLTexture, 300, 246, 'わかりやすい\n一律料金', '0x00A2E9')
  addPin(4, pinRPLTexture, 166, 1000, 'オンライン決済', '0x00A2E9')
  addPin(clickMoviePin, pinRPBexture, 132, 1466, 'コンセプトムービー')
  addPin(6, pinRPLTexture, 140, 794, '周辺の駐車場より\n20%程度安い', '0x00A2E9')
  addPin(7, pinRPLTexture, 292, 1696, '初期費用完全¥0', '0x00A2E9')
  addPin(8, pinRPTexture, 248, 2270, '一台分のスペースからできる')
  addPin(9, pinRPTexture, 114, 2184, '貸主収入アップ')
  addPin(10, pinRPTexture, 292, 1162, '法人サービスも完備')
  addPin(11, pinRPLTexture, 292, 1858, '1ヶ月からでも運用が可能', '0x00A2E9')
  addPin(12, pinRPTexture, 110, 2470, '0秒精算システム')
  addPin(13, pinRPTexture, 280, 2590, '既存のコインパーキングより\n90%〜30%安い')
}

// ピンの追加
const addPin = (pid, texture, posX, posY, text, color) => {
  const pin = new PIXI.Sprite(texture)
  pin.x = posX + 10
  pin.y = posY + 10
  pin.scale.set(0.7)
  pin.interactive = true
  mydata.container.pinContainer.addChild(pin)

  const childIndex = mydata.container.pinContainer.children.length

  if (text) {
    const setColor = (color) || '0xD51C45'
    const word = text
    const textobj = new PIXI.Text(word, {
      fontFamily: 'sans-serif, Yu Gothic',
      fontSize: '20px',
      align: 'center',
      fontWeight: '800',
      fill: setColor,
      lineHeight: 14,
      wordWrap: true
    })

    const popContainer = new PIXI.Container()
    // popContainer.visible = false

    const pinWidth = 50
    const popPaddingX = 25
    const popPaddingY = 20
    // let hoverAnim = null

    // textobj.anchor.set(0.5, 1)
    textobj.position.x = popPaddingX / 2
    textobj.position.y = popPaddingY / 2

    // pin.mouseover = (mouseData) => {
    //   if (hoverAnim)hoverAnim.pause()
    //   const pop = pin.getChildAt(0)
    //   pop.visible = true
    //   gsap.fromTo(pop, {
    //     alpha: 0,
    //     y: 10
    //   }, {
    //     alpha: 1,
    //     y: '-=20',
    //     duration: 0.5,
    //     ease: 'Power2.easeOut'
    //   })
    // }
    // pin.mouseout = (mouseData) => {
    //   const pop = pin.getChildAt(0)
    //   hoverAnim = gsap.fromTo(pin.getChildAt(0), {
    //     y: '+=0'
    //   }, {
    //     alpha: 0,
    //     y: '10',
    //     duration: 0.2,
    //     ease: 'Power2.easeOut',
    //     onComplete: () => {
    //       pop.visible = false
    //     }
    //   })
    // }
    if (typeof pid === 'number') {
      pin.tap = (e) => {
        router.push(`/detail/${pid}/`)
      }
    } else if (typeof pid === 'function') {
      pin.tap = () => {
        pid()
      }
    }
    const graphics = new PIXI.Graphics()
    graphics.lineStyle(2, setColor)
    graphics.beginFill(0xffffff, 1)
    graphics.drawRoundedRect(0, 0, textobj.width + popPaddingX, textobj.height + popPaddingY, 3)
    graphics.drawCircle((textobj.width + popPaddingX) / 2, textobj.height + popPaddingY + 12, 5)
    graphics.drawCircle((textobj.width + popPaddingX) / 2, textobj.height + popPaddingY + 30, 5)
    graphics.endFill()

    popContainer.addChild(graphics)
    popContainer.addChild(textobj)
    // popContainer.y -= 50
    popContainer.pivot.x = popContainer.width / 2
    popContainer.pivot.y = popContainer.height
    popContainer.x += pinWidth / 2
    // popContainer.y -= 5
    // popContainer.visible = false
    pin.addChild(popContainer)
  }

  // for debug
  // const debugText = new PIXI.Text(pid, {
  //   fontSize: '12px'
  // })
  // pin.addChild(debugText)

  gsap.fromTo(pin, {
    alpha: 0,
    y: '-=180'
  }, {
    alpha: 1,
    y: '+=180',
    duration: 0.5,
    ease: 'Back.easeOut',
    delay: childIndex * 0.07
  })
}

const addMovement = (movementName, posX, posY, originCenter) => {
  const lineTexture = PIXI.Texture.from(`assets/images/top/movement/${movementName}.png`)
  const lineSprite = new PIXI.Sprite(lineTexture)
  const colorTexture = PIXI.Texture.from(`assets/images/top/movement/${movementName}_c.png`)
  const colorSprite = new PIXI.Sprite(colorTexture)
  // colorSprite.blendMode = 2
  const objectContainer = new PIXI.Container()
  mydata.container[movementName] = objectContainer
  objectContainer.addChild(lineSprite)
  objectContainer.addChild(colorSprite)

  if (originCenter) {
    lineSprite.anchor.set(0.5, 0.5)
    colorSprite.anchor.set(0.5, 0.5)
  }
  objectContainer.x = posX
  objectContainer.y = posY
  if (posX === 0)objectContainer.alpha = 0
  mydata.moveObjects.push(objectContainer)
  // mydata.container.others.addChild(objectContainer)
  // console.log(mydata.container.mainContainer)
  mydata.container.movementSprite.addChild(objectContainer)
  return objectContainer
}

// const resizeHandler = () => {
// viewport.resize()
// viewport.fitHeight(1080)
// viewport.moveCenter(203 / 2, 1467 / 2)
// }

const mapShow = () => {
  app.stage.visible = true
  // viewport.visible = true

  const TL = gsap.timeline({ onComplete: endOpening })
  TL.from(mydata.container.ground, { alpha: 0, y: '-=100', duration: 2, ease: 'Back.easeOut' })
    .from(mydata.container.builds, { alpha: 0, y: '+=20', duration: 1.5, ease: 'Back.easeOut' }, '-=1')
    .from(mydata.container.others, { alpha: 0, y: '+=10', duration: 1, ease: 'Back.easeOut' }, '-=1')
    .from(mydata.container.movementSprite, { alpha: 0, duration: 1.5, ease: 'Back.easeOut' }, '-=1')
}

const clickMoviePin = () => {
  emit('clickMoviePin')
}

const setPathAnimation = (sprite, path, duration, delay, reverse, rotate) => {
  const timeline = gsap.timeline({ repeat: -1 })
  const start = (reverse) ? 0.49 : 0
  const end = (reverse) ? 0 : 0.49
  timeline
    .fromTo(sprite, { alpha: 0 }, { alpha: 1, duration: 0.2, ease: 'none', delay: delay })
    .to(sprite, {
      motionPath: {
        path: path,
        align: path,
        start: start,
        end: end,
        useRadians: true,
        autoRotate: rotate
      },
      ease: 'none',
      duration: duration,
      delay: '-0.5'
    })
    .to(sprite, { alpha: 0, duration: 0.2, ease: 'none', delay: '-0.5' })
}

const endOpening = () => {
  const car7 = addMovement('car08', 0, 0, true)
  setPathAnimation(car7, '#move15', 10, 1, true, -2.7)

  const car9 = addMovement('car07', 0, 0, true)
  setPathAnimation(car9, '#move16', 6, 5)

  const car1 = addMovement('car01', 0, 0, true)
  setPathAnimation(car1, '#move1', 8, 0)
  const car2 = addMovement('car02', 0, 0, true)
  setTimeout(setPathAnimation, 4000, car2, '#move1', 8, 0)
  const car3 = addMovement('car01', 0, 0, true)
  setTimeout(setPathAnimation, 5000, car3, '#move13', 3.5, 5, false, 0.45)
  const car4 = addMovement('car03', 0, 0, true)
  setPathAnimation(car4, '#move2', 8, 1, true)

  const car5 = addMovement('car07', 0, 0, true)
  setPathAnimation(car5, '#move4', 8, 1)
  const car6 = addMovement('car06', 0, 0, true)
  setPathAnimation(car6, '#move3', 8, 0.1, true)
  const car8 = addMovement('car09', 0, 0, true)
  setTimeout(setPathAnimation, 5000, car8, '#move3', 8, 0, true)

  const car11 = addMovement('car10', 0, 0, true)
  setPathAnimation(car11, '#move8', 3, 8, false)
  const car12 = addMovement('car06', 0, 0, true)
  setPathAnimation(car12, '#move14', 6, 1, true)
  const car13 = addMovement('car03', 0, 0, true)
  setPathAnimation(car13, '#move9', 2.5, 4, true)

  const car14 = addMovement('car11', 0, 0, true)
  setPathAnimation(car14, '#move7', 9, 2)
  const car15 = addMovement('car08', 0, 0, true)
  setPathAnimation(car15, '#move6', 3, 2, true)
  const car16 = addMovement('car10', 0, 0, true)
  setPathAnimation(car16, '#move5', 3, 1)
  const car17 = addMovement('car08', 0, 0, true)
  setPathAnimation(car17, '#move10', 9, 3, true)

  const car18 = addMovement('car01', 0, 0, true)
  setPathAnimation(car18, '#move11', 8, 7)

  gsap.to(mydata.container.baloon, {
    y: '+= 20',
    ease: 'Sine.easeInOut',
    repeat: -1,
    yoyo: true,
    duration: 3
  })

  gsap.to(mydata.container.cloudD, {
    y: '+= 10',
    ease: 'Sine.easeInOut',
    repeat: -1,
    yoyo: true,
    duration: 4
  })

  // マウス描画開始
  startDraw()
}

// マウス描画の開始
const startDraw = () => {
  mydata.state = 'draw'
  console.info('StartDrawing')
  drawGraphics.beginFill(0xffffff)
  app.stage.on('touchmove', e => {
    const { x, y } = app.stage.toLocal(new PIXI.Point(e.data.global.x, e.data.global.y))
    drawGraphics.drawCircle(x, y, 30, 30)
  })

  setTimeout(() => {
    stopDraw()
  }, drawTimer)
}

// マウス描画の停止
const stopDraw = () => {
  console.info('EndDrawing')
  drawGraphics.endFill()
  app.stage.removeAllListeners()

  const maskTexture = PIXI.Texture.from('assets/images/top/stageMask.png')
  const maskSprite = new PIXI.Sprite(maskTexture)
  maskSprite.anchor.set(0.5, 0.5)
  maskSprite.x = mydata.stageSize.width / 2
  maskSprite.y = window.innerHeight / 2
  // maskSprite.height = mydata.stageSize.height
  maskSprite.blendMode = 3
  maskContainer.addChild(maskSprite)
  gsap.fromTo(maskSprite, {
    width: 1,
    height: 1
  }, {
    width: window.innerHeight * 1.5,
    height: window.innerHeight * 1.5,
    duration: 1,
    ease: 'Power2.easeIn',
    onComplete: () => {
      mydata.container.mainContainer.mask = ''
      app.stage.removeChildAt(0) // 線の削除
      app.stage.removeChild(app.stage.getChildByName('ds'))

      // Make scrolling possible
      app.renderer.plugins.interaction.autoPreventDefault = false
      app.renderer.view.style.touchAction = 'auto'
      if (isMobile())document.body.style.overflow = 'auto'
      showMain()
    }
  })
}

// function debounce (fn, interval) {
//   let timer
//   return function (e) {
//     clearTimeout(timer)
//     timer = setTimeout(() => {
//       fn()
//     }, interval)
//   }
// }

defineExpose({
  mapShow
})

</script>

<style lang="scss" scoped>
  #mv{
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 7.2266;

    ::v-deep canvas{
      width: 100%;
      height: 100%;
    }
    svg{
      position: absolute;
      z-index: 1;
      visibility: hidden;
    }
    @include mq(sp) {
      .copy{
        display: none;
      }
    }
  }
</style>
