<template>
  <div id="mv">
    <img src="assets/images/top/main_copy.svg" alt="" class="copy">
    <svg id="move" xmlns="http://www.w3.org/2000/svg" width="1920" height="1080" viewBox="0 0 1920 1080">
      <rect id="stage" width="1920" height="1080" fill="none"/>
      <path id="move7" d="M1226.814,492.9c-7.461-4.053-19.09-10.321-31.4-16.958-29.589-15.951-63.126-34.031-67.21-36.6-5.828-3.67-10-4.106-18.152-4.673l.069-1a66.9,66.9,0,0,1,10.241,1.228,26.03,26.03,0,0,1,8.375,3.6c4.055,2.554,39.04,21.414,67.151,36.568,12.312,6.637,23.941,12.907,31.4,16.96Z" fill="#707070"/>
      <path id="move6" d="M247.787,117.452l-248-117,.427-.9,248,117Z" transform="translate(480.5 445.5)" fill="red"/>
      <path id="move5" d="M247.787,117.452l-248-117,.427-.9,248,117Z" transform="translate(464.5 454.733)" fill="red"/>
      <path id="move3" d="M287,136.219-.214.452l.427-.9L287.432,135.315Z" transform="translate(831.282 580.733)" fill="red"/>
      <path id="move1" d="M.212,372.78l-.424-.906L794.409-.453l.424.905Z" transform="translate(466.954 339.798)" fill="red"/>
      <path id="move2" d="M.21,370.454l-.421-.907,798-370,.421.907Z" transform="translate(483.5 349.5)" fill="red"/>
      <path id="move4" d="M284.787,134.452l-285-134,.426-.9,285,134Z" transform="translate(844.5 571.5)" fill="#707070"/>
      <path id="move8" d="M1815.532,1362.628l-.548-.836a101.469,101.469,0,0,1,13.749-7.227,78.986,78.986,0,0,1,13.388-4.52,42.728,42.728,0,0,1,14.415-1.108l.032,0a66.254,66.254,0,0,0,15.368,1.049,56.023,56.023,0,0,0,12.379-2.067,73.014,73.014,0,0,0,17.212-7.819c1.244-.72,2.418-1.4,3.471-1.955a17.138,17.138,0,0,1,6.29-1.865,21.375,21.375,0,0,1,5.622.205,22.607,22.607,0,0,1,5.621,1.652l-.433.9a22.061,22.061,0,0,0-5.381-1.571,20.35,20.35,0,0,0-5.347-.19,16.14,16.14,0,0,0-5.906,1.753c-1.035.545-2.2,1.22-3.436,1.935a74.9,74.9,0,0,1-17.438,7.915,57.03,57.03,0,0,1-12.6,2.1,67.241,67.241,0,0,1-15.585-1.061,41.747,41.747,0,0,0-14.049,1.088,77.952,77.952,0,0,0-13.21,4.459A100.423,100.423,0,0,0,1815.532,1362.628Z" transform="translate(-925 -678)" fill="#707070"/>
    </svg>
  </div>
</template>

<script setup>
import { reactive, onBeforeUnmount, defineExpose, onMounted, defineEmits } from 'vue'
import { useRouter } from 'vue-router'

// PixiJSを読み込み
import * as PIXI from 'pixi.js'
import { Viewport } from 'pixi-viewport'
import { gsap } from 'gsap'
import { PixiPlugin } from 'gsap/PixiPlugin'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const emit = defineEmits(['clickMoviePin'])
const router = useRouter()
gsap.registerPlugin(PixiPlugin)
gsap.registerPlugin(MotionPathPlugin)
gsap.registerPlugin(ScrollTrigger)

const mydata = reactive({
  container: {},
  moveObjects: [],
  state: 'movie',
  childPath: router.currentRoute.value.name
})

const drawTimer = 5000
let drawGraphics = null
let viewport = null
let maskContainer = null
let app = null

onBeforeUnmount(() => {
  app?.destroy()
})

onMounted(() => {
  init()
})

// イラストマップの初期化
// eslint-disable-next-line no-unused-vars
const init = () => {
  mydata.state = 'intro'

  gsap.fromTo('#intro .copy',
    { y: -120, alpha: 0 }, {
      y: 0,
      alpha: 1,
      ease: 'power1.out',
      scrollTrigger: {
        trigger: '#intro p',
        end: '+=500',
        // markers: true,
        scrub: true
      }
    }
  )

  gsap.fromTo('#mv .copy',
    { y: 0, alpha: 1 },
    {
      y: 120,
      alpha: 0,
      ease: 'power1.out',
      scrollTrigger: {
        trigger: '#intro p',
        end: '+=400',
        // markers: true,
        scrub: true
      }
    }
  )

  // ステージを作成
  app = new PIXI.Application({
    backgroundColor: 0xffffff,
    // backgroundAlpha: 0,
    // width: 1920,
    // height: 1080,
    antialias: true, // アンチエイリアスを有効にする
    resolution: window.devicePixelRatio || 1, // 解像度に合わせた拡大率を指定する
    autoDensity: true, // CSSで見た目のサイズの戻してくれる,
    resizeTo: document.getElementById('mv') // windowのリサイズに合わせてstage(canvas)をリサイズする
  })
  // app.renderer.plugins.interaction.autoPreventDefault = false
  // app.renderer.view.style.touchAction = 'none'
  // app.renderer.plugins.interaction.moveWhenInside = true
  PixiPlugin.registerPIXI(PIXI)
  document.getElementById('mv').appendChild(app.view)

  const mapContainer = new PIXI.Container()
  const mapContainerC = new PIXI.Container()

  /* -------------------------------------------------------------------------- */
  // 地面・駐車場
  const groundTexture = PIXI.Texture.from('assets/images/top/ground.png')
  const groundSprite = new PIXI.Sprite(groundTexture)
  mydata.container.ground = groundSprite
  // mapContainer.addChild(baseSprite)
  mapContainer.addChild(groundSprite)

  const groundColorTexture = PIXI.Texture.from('assets/images/top/color.png')
  const groundColorSprite = new PIXI.Sprite(groundColorTexture)
  groundColorSprite.blendMode = 2
  mydata.container.groundColor = groundColorSprite
  // groundColorSprite.visible = false
  mapContainerC.addChild(groundColorSprite)

  /* -------------------------------------------------------------------------- */
  const otherTexture = PIXI.Texture.from('assets/images/top/other.png') // 人・その他植物
  const othersSprite = new PIXI.Sprite(otherTexture)
  mydata.container.others = othersSprite
  mapContainer.addChild(othersSprite)

  const buildTexture = PIXI.Texture.from('assets/images/top/build.png') // 建物
  const buildsSprite = new PIXI.Sprite(buildTexture)
  mydata.container.builds = buildsSprite
  mapContainer.addChild(buildsSprite)

  /* -------------------------------------------------------------------------- */

  // アニメーションオブジェクトの追加
  addMovement('baloon', 1253, 103)
  addMovement('cloudA', 985, 89)
  addMovement('cloudB', 1359, 273)
  addMovement('cloudC', 1536, 139)

  /* -------------------------------------------------------------------------- */

  // const car1 = new PIXI.Sprite(carTex1)
  // mydata.container.car1 = car1

  // const carTex1 = PIXI.Texture.from('assets/images/top/car01.png')
  // const sprite4 = new PIXI.Sprite(carTex1)
  // mydata.container.sprite4 = sprite4

  // sprite3.anchor.set(0.5)
  // sprite4.anchor.set(0.5)

  // mydata.container.others.addChild(sprite3) // car
  // mydata.container.others.addChild(sprite4) // car

  viewport = new Viewport({
    // worldWidth: 1920,
    // worldHeight: 1080,
    interaction: app.renderer.plugins.interaction // the interaction module is important for wheel to work properly when renderer.view is placed or scaled
  })

  // // add the viewport to the stage
  app.stage.addChild(viewport)

  // // activate plugins
  viewport
    .drag({
      direction: 'x',
      wheel: false
    })
    // .pinch()
    // .wheel()
    .decelerate()
    // .moveCenter(1920 / 2, 1080 / 2)

  viewport.clamp({
    direction: 'all'
    // underflow: 'center'
  })

  // viewport.clampZoom({
  //   minWidth: null,
  //   minHeight: null,
  //   maxWidth: null,
  //   maxHeight: null
  //   // minScale: 0.7,
  //   // maxScale: 1.2
  // })

  const mainContainer = new PIXI.Container()
  mainContainer.addChild(mapContainer)
  mainContainer.addChild(mapContainerC)

  const lineContainer = new PIXI.Container()

  const brt = new PIXI.BaseRenderTexture(1920, 1080, PIXI.SCALE_MODES.LINEAR, 1)
  const rt = new PIXI.RenderTexture(brt)
  const sprite = new PIXI.Sprite(rt)
  lineContainer.addChild(sprite)
  mydata.container.lineContainer = lineContainer

  // デバッグ用
  // lineContainer.scale.x = 0.7
  // lineContainer.scale.y = 0.7

  viewport.addChild(lineContainer)
  viewport.addChild(mainContainer)
  viewport.visible = false
  viewport.fitHeight(viewport.worldHeight, true)
  viewport.moveCenter(1920 / 2, 1080 / 2)

  /* -------------------------------------------------------------------------- */
  /*                                   マスクの準備                                   */
  /* -------------------------------------------------------------------------- */
  const drawBRT = new PIXI.BaseRenderTexture(1920, 1080, PIXI.SCALE_MODES.NEAREST, 1)
  const drawRT = new PIXI.RenderTexture(drawBRT)
  const drawSprite = new PIXI.Sprite(drawRT)
  viewport.addChild(drawSprite)
  mainContainer.mask = drawSprite

  maskContainer = new PIXI.Container()
  drawGraphics = new PIXI.Graphics()
  drawGraphics.filters = [new PIXI.filters.BlurFilter(10)]
  maskContainer.addChild(drawGraphics)

  /* -------------------------------------------------------------------------- */

  app.ticker.add((delta) => {
    // console.log(mydata.container.baloon)
    for (const elem of mydata.moveObjects) {
      elem.children[1].alpha = 0
    }

    if (mydata.state === 'draw' || mydata.state === 'intro') app.renderer.render(mapContainer, { renderTexture: rt })

    for (const elem of mydata.moveObjects) {
      elem.children[1].alpha = 1
    }

    // 手書きのテクスチャ更新
    if (mydata.state === 'draw') app.renderer.render(maskContainer, { renderTexture: drawRT, clear: false })
  })

  window.addEventListener('resize', debounce(resizeHandler, 1000))
  // mapShow()
}

const showMain = () => {
  mydata.state = 'main'
  const pinContainer = new PIXI.Container()
  pinContainer.interactiveChildren = true
  mydata.container.pinContainer = pinContainer
  const pinRPTexture = PIXI.Texture.from('assets/images/top/pin_raku-p.png')
  const pinRPLTexture = PIXI.Texture.from('assets/images/top/pin_raku-p-light.png')
  const pinRPBexture = PIXI.Texture.from('assets/images/top/pin_raku-p-both.png')

  viewport.addChild(pinContainer)

  // ピンの追加 (id, テクスチャ, 座標x、座標y、吹き出しテキスト、吹き出し色)
  addPin(1, pinRPLTexture, 854, 168, '駐車場にできない部分も\n駐車場にできる', '0x00A2E9')
  addPin(2, pinRPTexture, 590, 225, '会員向けWポイント')
  addPin(3, pinRPLTexture, 1100, 231, 'わかりやすい\n一律料金', '0x00A2E9')
  addPin(4, pinRPLTexture, 308, 260, 'オンライン決済', '0x00A2E9')
  addPin(clickMoviePin, pinRPBexture, 751, 336, 'コンセプトムービー')
  addPin(6, pinRPLTexture, 515, 342, '周辺の駐車場より\n20%程度安い', '0x00A2E9')
  addPin(7, pinRPLTexture, 1150, 372, '初期費用完全¥0', '0x00A2E9')
  addPin(8, pinRPTexture, 1353, 408, '一台分のスペースからできる')
  addPin(9, pinRPTexture, 1058, 480, '貸主収入アップ')
  addPin(10, pinRPTexture, 413, 526, '法人サービスも完備')
  addPin(11, pinRPLTexture, 1461, 552, '1ヶ月からでも運用が可能', '0x00A2E9')
  addPin(12, pinRPTexture, 894, 598, '0秒精算システム')
  addPin(13, pinRPTexture, 1281, 624, '既存のコインパーキングより\n90%〜30%安い')
}

// ピンの追加
const addPin = (pid, texture, posX, posY, text, color) => {
  const pin = new PIXI.Sprite(texture)
  pin.x = posX
  pin.y = posY
  pin.interactive = true
  pin.defaultCursor = 'pointer'
  pin.buttonMode = true
  mydata.container.pinContainer.addChild(pin)

  const childIndex = mydata.container.pinContainer.children.length

  if (text) {
    const setColor = (color) || '0xD51C45'
    const word = text
    const textobj = new PIXI.Text(word, {
      fontFamily: 'sans-serif, Yu Gothic',
      fontSize: '24px',
      align: 'center',
      fontWeight: '800',
      fill: setColor,
      lineHeight: 17,
      wordWrap: true
    })

    const popContainer = new PIXI.Container()
    // popContainer.visible = false

    const pinWidth = 50
    const popPaddingX = 25
    const popPaddingY = 20
    let hoverAnim = null

    // textobj.anchor.set(0.5, 1)
    textobj.position.x = popPaddingX / 2
    textobj.position.y = popPaddingY / 2

    pin.mouseover = (mouseData) => {
      if (hoverAnim)hoverAnim.pause()
      const pop = pin.getChildAt(0)
      pop.visible = true
      gsap.fromTo(pop, {
        alpha: 0,
        y: 10
      }, {
        alpha: 1,
        y: '-=20',
        duration: 0.5,
        ease: 'Power2.easeOut'
      })
    }
    pin.mouseout = (mouseData) => {
      const pop = pin.getChildAt(0)
      hoverAnim = gsap.fromTo(pin.getChildAt(0), {
        y: '+=0'
      }, {
        alpha: 0,
        y: '10',
        duration: 0.2,
        ease: 'Power2.easeOut',
        onComplete: () => {
          pop.visible = false
        }
      })
    }
    if (typeof pid === 'number') {
      pin.click = () => {
        router.push(`/detail/${pid}/`)
      }
    } else if (typeof pid === 'function') {
      pin.click = () => {
        pid()
      }
    }
    const graphics = new PIXI.Graphics()
    graphics.lineStyle(2, setColor)
    graphics.beginFill(0xffffff, 1)
    graphics.drawRoundedRect(0, 0, textobj.width + popPaddingX, textobj.height + popPaddingY, 3)
    graphics.drawCircle((textobj.width + popPaddingX) / 2, textobj.height + popPaddingY + 12, 5)
    graphics.drawCircle((textobj.width + popPaddingX) / 2, textobj.height + popPaddingY + 30, 5)
    graphics.endFill()

    popContainer.addChild(graphics)
    popContainer.addChild(textobj)
    // popContainer.y -= 50
    popContainer.pivot.x = popContainer.width / 2
    popContainer.pivot.y = popContainer.height
    popContainer.x += pinWidth / 2
    // popContainer.y -= 5
    popContainer.visible = false
    pin.addChild(popContainer)
  }

  // for debug
  // const debugText = new PIXI.Text(pid, {
  //   fontSize: '12px'
  // })
  // pin.addChild(debugText)

  gsap.fromTo(pin, {
    alpha: 0,
    y: '-=180'
  }, {
    alpha: 1,
    y: '+=180',
    duration: 0.5,
    ease: 'Back.easeOut',
    delay: childIndex * 0.07
  })
}

const addMovement = (movementName, posX, posY, originCenter) => {
  const lineTexture = PIXI.Texture.from(`assets/images/top/movement/${movementName}.png`)
  const lineSprite = new PIXI.Sprite(lineTexture)
  const colorTexture = PIXI.Texture.from(`assets/images/top/movement/${movementName}_c.png`)
  const colorSprite = new PIXI.Sprite(colorTexture)
  colorSprite.blendMode = 2
  const objectContainer = new PIXI.Container()
  mydata.container[movementName] = objectContainer
  objectContainer.addChild(lineSprite)
  objectContainer.addChild(colorSprite)

  if (originCenter) {
    lineSprite.anchor.set(0.5, 0.5)
    colorSprite.anchor.set(0.5, 0.5)
  }
  objectContainer.x = posX
  objectContainer.y = posY
  if (posX === 0)objectContainer.alpha = 0
  mydata.moveObjects.push(objectContainer)
  mydata.container.others.addChild(objectContainer)
  return objectContainer
}

const resizeHandler = () => {
  viewport.resize()
  viewport.fitHeight(1080)
  viewport.moveCenter(1920 / 2, 1080 / 2)
}

const mapShow = () => {
  viewport.visible = true

  const TL = gsap.timeline({ onComplete: endOpening })
  TL.from(mydata.container.ground, { alpha: 0, y: '-=100', duration: 2, ease: 'Back.easeOut' })
    .from(mydata.container.builds, { alpha: 0, y: '+=20', duration: 1.5, ease: 'Back.easeOut' }, '-=1')
    .from(mydata.container.others, { alpha: 0, y: '+=10', duration: 1, ease: 'Back.easeOut' }, '-=1')
}

const setPathAnimation = (sprite, path, duration, delay, reverse, rotate) => {
  const timeline = gsap.timeline({ repeat: -1 })
  const start = (reverse) ? 0.49 : 0
  const end = (reverse) ? 0 : 0.49
  timeline
    .fromTo(sprite, { alpha: 0 }, { alpha: 1, duration: 0.2, ease: 'none', delay: delay })
    .to(sprite, {
      motionPath: {
        path: path,
        align: path,
        start: start,
        end: end,
        useRadians: true,
        autoRotate: rotate
      },
      ease: 'none',
      duration: duration,
      delay: '-0.5'
    })
    .to(sprite, { alpha: 0, duration: 0.2, ease: 'none', delay: '-0.5' })
}

const clickMoviePin = () => {
  emit('clickMoviePin')
}

const endOpening = () => {
  // setPathAnimation(mydata.container.car01, '#move1', 9, 0.5)
  const car1 = addMovement('car01', 0, 0, true)
  setPathAnimation(car1, '#move1', 12, 0)
  const car5 = addMovement('car02', 0, 0, true)
  setTimeout(setPathAnimation, 7000, car5, '#move1', 12, 0)

  const car2 = addMovement('car03', 0, 0, true)
  setPathAnimation(car2, '#move2', 12, 1, true)
  const car6 = addMovement('car04', 0, 0, true)
  setTimeout(setPathAnimation, 4000, car6, '#move2', 12, 0, true)
  const car7 = addMovement('car05', 0, 0, true)
  setTimeout(setPathAnimation, 8500, car7, '#move2', 12, 0, true)

  const car3 = addMovement('car07', 0, 0, true)
  setPathAnimation(car3, '#move3', 8, 1)
  const car4 = addMovement('car06', 0, 0, true)
  setPathAnimation(car4, '#move4', 8, 0.1, true)

  const car9 = addMovement('car07', 0, 0, true)
  setPathAnimation(car9, '#move5', 8, 0)
  const car8 = addMovement('car06', 0, 0, true)
  setPathAnimation(car8, '#move6', 8, 1, true)

  const car10 = addMovement('car07', 0, 0, true)
  setPathAnimation(car10, '#move7', 5, 5, false, 2.6)

  const car11 = addMovement('car07', 0, 0, true)
  setPathAnimation(car11, '#move8', 6, 6, true, 2.65)

  // setPathAnimation(mydata.container.car02, '#move3', 5, 0)
  // setPathAnimation(mydata.container.car02, '#move4', 4, 0.2)

  // gsap.to(mydata.container.sprite4, {
  //   motionPath: {
  //     path: '#move2',
  //     align: '#move2',
  //     end: 0.5
  //   },
  //   repeat: -1,
  //   duration: 5,
  //   ease: 'linear'
  // })
  gsap.to(mydata.container.baloon, {
    y: '+= 20',
    ease: 'Sine.easeInOut',
    repeat: 100,
    yoyo: true,
    duration: 3
  })

  // マウス描画開始
  startDraw()
}

// マウス描画の開始
const startDraw = () => {
  mydata.state = 'draw'
  console.info('StartDrawing')
  drawGraphics.beginFill(0xffffff)
  // this.drawGraphics.filters = [new PIXI.filters.BlurFilter(10)]
  viewport.on('mousemove', e => {
    const { x, y } = viewport.toLocal(new PIXI.Point(e.data.global.x, e.data.global.y))
    // const { x, y } = e.data.global
    drawGraphics.drawCircle(x, y, 50, 50)
  })

  setTimeout(() => {
    stopDraw()
  }, drawTimer)
}

// マウス描画の停止
const stopDraw = () => {
  console.info('EndDrawing')
  drawGraphics.endFill()
  // viewport.removeAllListeners()

  const maskTexture = PIXI.Texture.from('assets/images/top/stageMask.png')
  const maskSprite = new PIXI.Sprite(maskTexture)
  maskSprite.anchor.set(0.5)
  maskSprite.x = 1920 / 2
  maskSprite.y = 1080 / 2
  maskSprite.blendMode = 3
  maskContainer.addChild(maskSprite)
  gsap.fromTo(maskSprite, {
    width: 1,
    height: 1
  }, {
    width: 1920 * 1.5,
    height: 1920 * 1.5,
    duration: 2,
    ease: 'Power2.easeIn',
    onComplete: () => {
      viewport.removeChildAt(0)
      showMain()
    }
  })
}

function debounce (fn, interval) {
  let timer
  return function (e) {
    clearTimeout(timer)
    timer = setTimeout(() => {
      fn()
    }, interval)
  }
}

defineExpose({
  mapShow
})

</script>

<style lang="scss" scoped>
  #mv{
    // display: flex;
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: 100%;
    // align-items: center;
    // position: fixed;
    height: calc(100vh - 80px);
    canvas{
      // width: 100%;
      // height: 100%;
    }
    .copy{
      position: absolute;
      bottom: 8vh;
    }
    svg{
      position: absolute;
      z-index: 1;
      visibility: hidden;
    }
    @include mq(sp) {
      height: calc(100vh - var(--header-height-sp));
      .copy{
        display: none;
      }
    }
  }
</style>
